import { Color, Font } from "@/design-system";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";
import TreatmentList from "./TreatmentList";
import { H2 } from "@/design-system";
import { MedicalItem } from "@/apis/patientApi/index.type";

type TreatmentSectionProps = {
  medicalItem: MedicalItem[];
};

const TreatmentSection = ({ medicalItem }: TreatmentSectionProps) => {
  return (
    <Wrapper>
      <TextContainer>
        <Font type="semibold_16" color="accent">
          최저가 병원 예약
        </Font>
        <H2
          fontType="h1"
          textAlign="center"
        >{`독감 예방접종, 탈모약부터\n다이어트 처방 모두 최저가로`}</H2>
        <Font type="regular_16" color="medium" style={{ textAlign: "center" }}>
          {`같은 성분이지만 다른 처방 가격\n나만의닥터가 우리 동네 최저가를 찾아 드릴게요.`}
        </Font>
      </TextContainer>
      <TreatmentList medicalItem={medicalItem} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: ${DEFAULT_PADDING * 2}px 0;
  display: flex;
  flex-direction: column;
  background-color: ${Color.greyScale2};
`;

const TextContainer = styled.div`
  padding: ${DEFAULT_PADDING}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export default TreatmentSection;
