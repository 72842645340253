import { ParsedUrlQuery } from "querystring";

type GetHospitalHubEndpoint = {
  newQuery?: ParsedUrlQuery;
  prevQuery: ParsedUrlQuery;
  pathname: string;
};

export const getHospitalHubQueryString = ({
  newQuery,
  prevQuery,
  pathname,
}: GetHospitalHubEndpoint) => {
  if (typeof window === undefined) return "";

  const params = pathname.includes("hospital")
    ? ({
        ...prevQuery,
        ...newQuery,
      } as Record<string, string>)
    : ({ ...newQuery } as Record<string, string>);

  return `?${new URLSearchParams(params).toString()}`;
};
