import { Button, Color, Font } from "@/design-system";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";
import { H2, H3 } from "@/design-system";

const DoctorSection = () => {
  return (
    <Container id="partner-section">
      <TopTextContainer>
        <Font type="semibold_16" color="medium">
          {`의사/약사 선생님이신가요?`}
        </Font>
        <H2
          fontType="h1"
          textAlign="center"
          color="white"
        >{`우리동네 신환 유치\n나만의닥터에서 시작하세요`}</H2>
      </TopTextContainer>
      <ContentContainer>
        <Content>
          <H3 fontType="h2" color="white">
            병원 제휴하기
          </H3>
          <Font type="regular_16" color="medium">
            {`50만명이 이용 중인 나만의닥터에서\n50명의 신환이 매일 우리 병원에\n찾아오기 시작합니다.`}
          </Font>
          <Button.Medium
            level="primary"
            type="hug"
            text="제휴 신청하기"
            onPress={() => {
              window.open("https://form.typeform.com/to/nMbLfOZK", "_blank");
            }}
            style={{ marginTop: "8px" }}
          />
        </Content>
        <Content>
          <H3 fontType="h2" color="white">
            약국 제휴하기
          </H3>
          <Font type="regular_16" color="medium">
            {`나만의닥터는 약국을\n임의로 연결하지 않습니다.\n합법적인 비대면 진료 플랫폼,\n나만의닥터와 함께 하세요.`}
          </Font>
          <Button.Medium
            level="primary"
            type="hug"
            text="제휴 신청하기"
            onPress={() => {
              window.open("https://form.typeform.com/to/uHXLLPVk", "_blank");
            }}
            style={{ marginTop: "8px" }}
          />
        </Content>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${DEFAULT_PADDING * 2}px;
  background-color: ${Color.black};
`;

const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${DEFAULT_PADDING}px;
  gap: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${DEFAULT_PADDING}px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${DEFAULT_PADDING}px 0;
  gap: 8px;
`;

export default DoctorSection;
