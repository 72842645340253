import { SvgIconName } from "@/design-system";

export const treatmentTypeToIconName: Record<string, SvgIconName> = {
  gardasil: "gardasil",
  diet: "diet",
  hair_loss: "hairloss",
  acne_medicine: "skin",
  flu: "herpes",
  diet_oral_medication: "pillColor",
};
