import { ColorKey, SvgIcon, SvgIconName } from "@/design-system";
import { HospitalList } from "@/pages/index";
import { useState } from "react";
import Collapsible from "react-collapsible";
import styled from "styled-components";

type Props = {
  title: string;
  data: HospitalList[];
  iconName: SvgIconName;
  color: ColorKey;
};

const Accordion = ({ title, data, iconName, color }: Props) => {
  const [isTriggerOpen, setIsTriggerOpen] = useState(false);

  return (
    <Collapsible
      trigger={
        <Container>
          <TitleWrapper>
            <SvgIcon size={20} iconName={iconName} color={color} />
            <Title>{title}</Title>
          </TitleWrapper>

          <SvgIcon
            size={20}
            iconName={isTriggerOpen ? "chevronUp" : "chevronDown"}
            color="medium"
          />
        </Container>
      }
      onTriggerClosing={() => setIsTriggerOpen(false)}
      onTriggerOpening={() => setIsTriggerOpen(true)}
      transitionTime={200}
      easing="ease-out"
      key={title}
      contentElementId={title}
    >
      <ListContainer>
        {data.map((department) => (
          <a
            style={{ textDecoration: "none" }}
            key={department.value}
            href={`/hospital/deptHub/region/${department.label}-서울`}
          >
            <DepartmentText key={department.value}>
              {department.label}
            </DepartmentText>
          </a>
        ))}
      </ListContainer>
    </Collapsible>
  );
};

export default Accordion;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 20px;
  border-bottom: 1px solid #f1f2f4;
  background: white;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Title = styled.div`
  color: #474a52;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: pre-line;
`;

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background: #f9fafa;
`;

const DepartmentText = styled.div`
  color: #474a52;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
