import { patientApi } from "@/apis/patientApi";
import { MedicalItem } from "@/apis/patientApi/index.type";
import { webApi } from "@/apis/webApi";
import {
  Container,
  Content,
  FooterContainer,
  HeaderContainer,
} from "@/components/Container/index.style";
import FooterArea from "@/components/FooterArea";
import HeaderArea from "@/components/HeaderArea";
import ContentMeta, { ContentMetaProps } from "@/components/Meta/ContentMeta";
import MixpanelEvents from "@/constants/mixpanel";
import useMixpanel from "@/hooks/useMixpanel";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import styled from "styled-components";
import DepartmentHubSection from "./home/DepartmentHubSection";
import DoctorSection from "./home/DoctorSection";
import HealthContentSection from "./home/HealthContentSection";
import HomeContentSection from "./home/HomeContentSection";
import HomeSubsidySection from "./home/HomeSubsidySection";
import PartnerSection from "./home/PartnerSection";
import TopSection from "./home/TopSection";
import TreatmentSection from "./home/TreatmentSection";

const HomeContainer = styled(Container)``;

export type HospitalList = {
  type: "department_code";
  value: string;
  label: string;
};

type MyDoctorHomeProps = {
  medicalItem: MedicalItem[];
  sundayHospitalList: HospitalList[];
  nightHospitalList: HospitalList[];
  specialistHospitalList: HospitalList[];
  telemedicineHospitalList: HospitalList[];
};

const MyDoctorHome = ({
  medicalItem,
  nightHospitalList,
  specialistHospitalList,
  sundayHospitalList,
  telemedicineHospitalList,
}: MyDoctorHomeProps) => {
  const mixpanel = useMixpanel();
  const router = useRouter();

  const homeJsonLd = JSON.stringify({
    "@context": "http://schema.org",
    "@type": "ItemList",
    itemListOrder: "http://schema.org/ItemListOrderAscending",
    description: "의료가 필요한 순간은 언제나, 나만의닥터",
    itemListElement: [
      {
        "@type": "ListItem",
        name: "비대면 진료",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_01.png",
        url: "https://my-doctor.io/",
        position: "1",
      },
      {
        "@type": "ListItem",
        name: "약 수령 안내",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_03.png",
        url: "https://my-doctor.io/event/55",
        position: "2",
      },
      {
        "@type": "ListItem",
        name: "최저가 병원 비교",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_02.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=34",
        position: "3",
      },
      {
        "@type": "ListItem",
        name: "매일 돈 버는 건강관리",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_04.png",
        url: "https://my-doctor.io/quiz",
        position: "4",
      },
      {
        "@type": "ListItem",
        name: "전국민 건강지원금",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_05.png",
        url: "https://my-doctor.io/event/41",
        position: "5",
      },
      {
        "@type": "ListItem",
        name: "우리동네 병원 비교",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_06.png",
        url: "https://my-doctor.io/hospital/deptHub/region/내과-서울",
        position: "6",
      },
      {
        "@type": "ListItem",
        name: "병원/약국 제휴 안내",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_07.png",
        url: "https://my-doctor.io/#partner-section",
        position: "7",
      },
      {
        "@type": "ListItem",
        name: "건강꿀팁",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_08.png",
        url: "https://my-doctor.io/healthLab/info/list",
        position: "8",
      },
      {
        "@type": "ListItem",
        name: "건강FAQ",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_09.png",
        url: "https://my-doctor.io/healthLab/FAQ",
        position: "9",
      },
      {
        "@type": "ListItem",
        name: "질환백과",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_10.png",
        url: "https://my-doctor.io/healthLab/health-wiki",
        position: "10",
      },
      {
        "@type": "ListItem",
        name: "탈모 최저가 성지 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_11.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=34",
        position: "11",
      },
      {
        "@type": "ListItem",
        name: "가다실9가 최저가 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_12.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=1",
        position: "12",
      },
      {
        "@type": "ListItem",
        name: "다이어트 주사제 최저가 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_13.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=2",
        position: "13",
      },
      {
        "@type": "ListItem",
        name: "다이어트 약 최저가 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_14.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=4",
        position: "14",
      },
      {
        "@type": "ListItem",
        name: "여드름 약 최저가 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_15.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=5",
        position: "15",
      },
      {
        "@type": "ListItem",
        name: "독감 예방접종 최저가 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_16.png",
        url: "https://my-doctor.io/hospital?treatment_type_id=6",
        position: "16",
      },
      {
        "@type": "ListItem",
        name: "달빛 어린이 병원 • 야간진료 소아과",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_17.png",
        url: "https://my-doctor.io/hospital/deptHub/region/소아청소년과-서울",
        position: "17",
      },
      {
        "@type": "ListItem",
        name: "일요일 진료 소아과",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_18.png",
        url: "https://my-doctor.io/hospital/deptHub/list/region/소아청소년과-일요일진료-서울",
        position: "18",
      },
      {
        "@type": "ListItem",
        name: "소아과 전문의 병원",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_19.png",
        url: "https://my-doctor.io/hospital/deptHub/region/소아청소년과-서울",
        position: "19",
      },
      {
        "@type": "ListItem",
        name: "비대면 진료 소아과",
        image:
          "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2024/04/24/seo_home_20.png",
        url: "https://my-doctor.io/hospital/deptHub/list/region/소아청소년과-비대면진료-서울",
        position: "20",
      },
    ],
  });

  const metaData: ContentMetaProps = {
    title: `나만의닥터 - 대한민국 1위 비대면 진료 어플`,
    description:
      "비대면 진료 1위 앱 나만의닥터에서 원격 진료 받으세요! 일요일 진료 가능한 소아과, 산부인과 처방부터 탈모약, 다이어트 주사(위고비, 삭센다 등), 가다실, 독감 예방접종까지 최저가 진료 및 약국 찾기가 가능해요",
    keywords:
      "비대면진료, 원격진료, 원격의료, 비대면진료앱, 비대면진료서비스, 비대면진료어플, 원격진료앱, 원격진료서비스, 원격진료어플, 원격의료앱, 원격의료서비스, 원격의료어플, 약배달, 약배송, 약국배달, 전화처방, 약처방, 온라인처방, 재택치료, 약배달어플, 약배달앱, 약배달서비스, 약배송어플, 약배송앱, 약배송서비스, 약국배달어플, 약국배달앱, 약국배달서비스, 약처방앱, 약처방어플, 온라인처방어플, 온라인처방앱, 코로나상비약, 코로나비대면진료, 나만의닥터, 메라키플레이스, 병원예약, 병원검색, 병원후기, 병원리뷰, 피부과, 산부인과, 비뇨기과, 정형외과, 정신건강의학과, 정신과, 안과, 내과, 이비인후과, 소아과, 가정의학과, 소아청소년과, 만보기어플추천, 앱테크, 리워드앱, 건강관리, 혈당기록, 혈압기록, 체중기록, 복약기록, 건강퀴즈, 나만의닥터코드, 걷기모임코드, 최저가병원찾기, 병원가격비교, 독감백신, 독감예방접종가격, 삭센다, 다이어트주사, 삭센다가격, 삭센다비대면, 삭센다처방, 삭센다가격비교, 탈모약처방, 탈모약가격, 탈모약가격비교, 탈모약비대면, 탈모약디씨, 탈모약종로, 탈모약강남, 삭센다종로, 삭센다강남, 가다실9가가격, 가다실가격, 가다실남자, 가다실부작용, 가다실9가, 가다실, 여드름약처방, 여드름약비대면, 여드름약가격비교, 이소티논가격, 이소티논가격비교, 이소티논비대면, 이소티논처방, 건강지원금, 건강후원금",
    image:
      "https://d7qkbi83dtokl.cloudfront.net/prod/web/landingPage/image/home/MYD-WebOG.png",
    width: 800,
    height: 400,
    ogTitle: "나만의닥터 - 대한민국 1위 비대면 진료",
    ogDescription: "안전한 비대면 진료 • 최저가 병원/약국 예약 앱",
    twitterTitle: "나만의닥터 - 대한민국 1위 비대면 진료",
    twitterDescription: "안전한 비대면 진료 • 최저가 병원/약국 예약 앱",
    jsonLd: homeJsonLd,
  };

  useEffect(() => {
    if (!router.isReady) return;

    mixpanel.track(MixpanelEvents.PageView.Home);
  }, [router.isReady]);

  return (
    <HomeContainer>
      <ContentMeta data={metaData} />
      <HeaderContainer isHeaderWhite={true}>
        <HeaderArea isHomeQuickLink={true} />
      </HeaderContainer>
      <Content>
        <TopSection />
        <HomeContentSection />
        <TreatmentSection medicalItem={medicalItem} />
        <DepartmentHubSection
          nightHospitalList={nightHospitalList}
          specialistHospitalList={specialistHospitalList}
          sundayHospitalList={sundayHospitalList}
          telemedicineHospitalList={telemedicineHospitalList}
        />
        <HealthContentSection />
        <HomeSubsidySection />
        <DoctorSection />
        <PartnerSection />
      </Content>
      <FooterContainer>
        <FooterArea />
      </FooterContainer>
    </HomeContainer>
  );
};

export default MyDoctorHome;

export const getStaticProps: GetStaticProps = async () => {
  const medicalItem = await patientApi.getHospitalMedicalItem();

  const response = await webApi.getDepartmentHubList();

  const departmentHubList = response.data.filter_values.filter(
    (filter) => filter.filter_label !== "전체 병원"
  );

  const sundayHospitalList = departmentHubList.find(
    (hospital) => hospital.filter_name === "open_sunday"
  ).filter_values;

  const nightHospitalList = departmentHubList.find(
    (hospital) => hospital.filter_name === "open_night"
  ).filter_values;

  const specialistHospitalList = departmentHubList.find(
    (hospital) => hospital.filter_name === "specialist"
  ).filter_values;

  const telemedicineHospitalList = departmentHubList.find(
    (hospital) => hospital.filter_name === "telemedicine"
  ).filter_values;

  return {
    props: {
      medicalItem,
      sundayHospitalList,
      nightHospitalList,
      specialistHospitalList,
      telemedicineHospitalList,
    },
    revalidate: 60,
  };
};
