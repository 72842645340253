import {
  CONTACT_POINT,
  LOGO_URL,
  SAME_AS,
  SERVER_URL,
  SITE_NAME,
} from "@/components/Meta/ContentMeta/constants";

export const getOrganizationJsonLd = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    name: SITE_NAME,
    url: SERVER_URL,
    logo: LOGO_URL,
    contactPoint: CONTACT_POINT,
    sameAs: SAME_AS,
  });
};
