import { motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const NUDGE_LIST = [
  {
    text: "네이버페이",
    path: "/image/quiz/image_naverPay.png",
  },
  {
    text: "스타벅스",
    path: "/image/quiz/image_starbucks.png",
  },
  {
    text: "정관장 기프티콘",
    path: "/image/quiz/image_ginseng.png",
  },
  {
    text: "신계계 상품권",
    path: "/image/quiz/image_shinsegue.png",
  },
  {
    text: "CU",
    path: "/image/quiz/image_cu.png",
  },
  {
    text: "GS25",
    path: "/image/quiz/image_gs.png",
  },
  {
    text: "세븐일레븐",
    path: "/image/quiz/image_seven_eleven.png",
  },
];

const NudgeCarousel = () => {
  const [containerWidth, setContainerWidth] = useState(0);

  const [translateXValue, setTranslateXValue] = useState(0);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const width =
      (divRef.current?.scrollWidth || 0) - (divRef.current?.clientWidth || 0);
    setContainerWidth(width);
    setTranslateXValue(width);
  }, []);

  return (
    <Container>
      <MotionDiv
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          overflow: "visible",
        }}
        ref={divRef}
        animate={{
          translateX: -translateXValue,
        }}
        onAnimationComplete={() => {
          if (translateXValue === 0) {
            setTranslateXValue(containerWidth);
          } else {
            setTranslateXValue(0);
          }
        }}
        transition={{ ease: "linear", duration: 10 }}
      >
        {[...NUDGE_LIST, ...NUDGE_LIST].map((nudge, idx) => {
          return (
            <div
              style={{
                width: "56px",
                height: "56px",
                display: "flex",
                flex: "auto",
                flexShrink: 0,
              }}
              key={idx}
            >
              <Image src={nudge.path} width={56} height={56} alt={nudge.text} />
            </div>
          );
        })}
      </MotionDiv>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  overflow: hidden;
  -webkit-scrollbar: {
    display: none;
  }
`;

const MotionDiv = styled(motion.div)`
  -webkit-scrollbar: {
    display: none;
  }
`;

export default NudgeCarousel;
