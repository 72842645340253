import { MedicalItem } from "@/apis/patientApi/index.type";
import { treatmentTypeToIconName } from "@/constants/treatmentTypeToIconName";
import { Color, Font, SvgIcon } from "@/design-system";
import { getHospitalHubQueryString } from "@/utils/hrefFormatter/hospitalHub";
import { useRouter } from "next/router";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";

type TreatmentListProps = {
  medicalItem: MedicalItem[];
};

const TreatmentList = ({ medicalItem }: TreatmentListProps) => {
  const { query, pathname } = useRouter();
  const href = (id: number) =>
    getHospitalHubQueryString({
      newQuery: { treatment_type_id: String(id) },
      prevQuery: query,
      pathname,
    });

  return (
    <Wrapper>
      {medicalItem.map((treatment) => {
        return (
          <ListItem
            hrefLang={"ko"}
            key={treatment.name}
            style={{ gap: 8 }}
            href={`/hospital${href(treatment.id)}`}
          >
            <SvgIcon
              iconName={treatmentTypeToIconName[treatment.type]}
              size={32}
            />
            <Font type="regular_14" color="dark">
              {treatment.name}
            </Font>
          </ListItem>
        );
      })}

      <ListItem
        hrefLang={"ko"}
        style={{ gap: 8 }}
        href={`/hospital/shingles/region/서울`}
      >
        <SvgIcon iconName={"shingles_vaccine"} size={32} />
        <Font type="regular_14" color="dark">
          {"대상포진 백신"}
        </Font>
      </ListItem>

      <ReadyForListItem style={{ gap: 8 }}>
        <div style={{ opacity: 0.5 }}>
          <SvgIcon iconName={"mesotherapy"} size={32} />
        </div>
        <div style={{ opacity: 0.5 }}>
          <Font type="regular_14" color="dark">
            {"탈모 시술"}
          </Font>
        </div>
        <Badge>준비 중</Badge>
      </ReadyForListItem>

      <ReadyForListItem style={{ gap: 8 }}>
        <div style={{ opacity: 0.5 }}>
          <SvgIcon iconName={"massage"} size={32} />
        </div>
        <div style={{ opacity: 0.5 }}>
          <Font type="regular_14" color="dark">
            {"도수치료"}
          </Font>
        </div>
        <Badge>준비 중</Badge>
      </ReadyForListItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 12px;
  padding: 12px ${DEFAULT_PADDING}px 0 ${DEFAULT_PADDING}px;
`;

const ListItem = styled.a`
  background-color: ${Color.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-basis: 30%;
  padding: 24px 0;
  cursor: pointer;
  text-decoration: none;
`;

const ReadyForListItem = styled.div`
  position: relative;
  background-color: ${Color.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-basis: 30%;
  padding: 24px 0;
  text-decoration: none;
`;

const Badge = styled.div`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--dim-thick, rgba(30, 32, 36, 0.7));
  color: var(--fill-white, var(--fill-white, #fff));

  /* body3_medium */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */

  position: absolute;
`;

export default TreatmentList;
