import { Button, Font } from "@/design-system";
import Image from "next/image";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";

const PartnerSection = () => {
  return (
    <Container>
      <Image
        src="/image/doctorSection.png"
        alt="background-image"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        priority={true}
      />
      <Overlay />
      <TextContainer>
        <Font type="semibold_24" color="white" style={{ textAlign: "center" }}>
          {`의사가 만든 의료 서비스로서\n언제나 의료인의 마음으로\n함께 성장하겠습니다.`}
        </Font>
        <Font
          type="regular_16"
          color="mediumLight"
          style={{ textAlign: "center" }}
        >
          {`대한민국 No.1 의료 플랫폼의 책임감으로\n의료인과 함께 성장할 수 있는 의료 환경을\n만들어나가는데 앞장서겠습니다.`}
        </Font>
      </TextContainer>
      <Button.Medium
        text="나만의닥터 파트너 스토리"
        level="secondary"
        onPress={() => {
          window.open(
            "https://merakiplace.notion.site/50-725dd0a202bc4242a505ceafea826537?pvs=4",
            "_blank"
          );
        }}
        type="hug"
        style={{ zIndex: 2 }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${DEFAULT_PADDING * 2}px 0;
  height: 400px;
  position: relative;
  z-index: 2;
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(71, 74, 82, 0.6);
`;

const TextContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 32px;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
  z-index: 2;
`;

export default PartnerSection;
