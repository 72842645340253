import { getOrganizationJsonLd } from "@/components/Meta/ContentMeta/Organization";
import Head from "next/head";

export type ContentMetaProps = {
  title: string;
  description?: string;
  keywords?: string;
  image?: string;
  width?: number;
  height?: number;
  canonical?: string;
  type?: string;
  author?: string;
  jsonLd?: string[] | string | null;

  // og Layer
  ogTitle?: string;
  ogDescription?: string;

  // twitter Layer
  twitterTitle?: string;
  twitterDescription?: string;
};

const ContentMeta = ({
  data,
  isDept,
}: {
  data: ContentMetaProps;
  isDept?: boolean;
}) => {
  const OrganizationJsonLd = getOrganizationJsonLd();

  const {
    title,
    description = "나만의닥터 앱으로 비대면 진료도 안전하게 시작하세요. 실력 있는 전문의에게 비대면 진료를 예약하고, 약 수령도 간편하게",
    author = "나만의닥터",
    type = "website",
    keywords: _keywords,
    canonical: _canonical = "",
    image,
    width = 800,
    height = 400,
    jsonLd,
    ogTitle: _ogTitle,
    ogDescription: _ogDescription,
    twitterTitle: _twitterTitle,
    twitterDescription: _twitterDescription,
  } = data;
  const canonical = `https://my-doctor.io/${_canonical}`;

  const defaultKeywords =
    "비대면진료, 원격진료, 원격의료, 비대면진료앱, 비대면진료서비스, 비대면진료어플, 원격진료앱, 원격진료서비스, 원격진료어플, 원격의료앱, 원격의료서비스, 원격의료어플, 약배달, 약배송, 약국배달, 전화처방, 약처방, 온라인처방, 재택치료, 약배달어플, 약배달앱, 약배달서비스, 약배송어플, 약배송앱, 약배송서비스, 약국배달어플, 약국배달앱, 약국배달서비스, 약처방앱, 약처방어플, 온라인처방어플, 온라인처방앱, 코로나상비약, 코로나비대면진료";
  const rawKeywords = _keywords
    ? `${defaultKeywords}, ${_keywords}`
    : defaultKeywords;

  const keywords = Array.from(
    new Set(rawKeywords.split(", ").filter(Boolean))
  ).join(", ");

  const domain = process.env.NEXT_PUBLIC_DOMAIN;
  const robotsContent =
    domain === "my-doctor.io"
      ? "index, follow"
      : "dev.my-doctor.io"
      ? "noindex, nofollow"
      : "noindex, nofollow";

  const ogTitle = _ogTitle ? _ogTitle : title;
  const twitterTitle = _twitterTitle ? _twitterTitle : title;

  const ogDescription = _ogDescription ? _ogDescription : description;
  const twitterDescription = _twitterDescription
    ? _twitterDescription
    : description;

  return (
    <>
      <Head>
        {/*Title*/}
        <title>{title}</title>
        <meta httpEquiv="Title" content={title} />
        <meta property="og:title" content={ogTitle} />
        <meta name="twitter:title" content={twitterTitle} />
        {/*description*/}
        <meta name="description" content={description} />
        <meta property="og:description" content={ogDescription} />
        <meta name="twitter:description" content={twitterDescription} />
        {/* 도메인 주소관련 정보 */}
        <link rel="canonical" href={canonical} />
        <meta property="og:url" content={canonical} />
        <link rel="alternate" href={canonical} hrefLang="ko_KR" />

        <meta name="robots" content={robotsContent} />
        <meta property="og:type" content={type} />
        <meta name="author" content={author} />
        <meta name="keywords" content={keywords} />
        {/*이미지 관련*/}
        {image ? <meta name="twitter:image" content={image} /> : null}
        {image ? <link rel="image_src" href={image} /> : null}
        {image ? <meta property="og:image" content={image} /> : null}
        {image ? <meta itemProp="image" content={image} /> : null}
        {image && width ? (
          <meta property="og:image:width" content={String(width)} />
        ) : null}
        {image && height ? (
          <meta property="og:image:height" content={String(height)} />
        ) : null}
        {isDept ? null : (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: OrganizationJsonLd,
            }}
          />
        )}
        {!jsonLd ? null : typeof jsonLd === "string" ? (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: jsonLd }}
            key={jsonLd}
          />
        ) : jsonLd.length > 0 ? (
          jsonLd
            .filter((item) => item)
            .map((json) => (
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: json }}
                key={json}
              />
            ))
        ) : null}
        <link rel="icon" href="/favicon.png" title="나만의닥터" />
        <link rel="shortcut icon" href="/favicon.png" title="나만의닥터" />
        <meta httpEquiv="publisher" content="나만의닥터" />
        <meta httpEquiv="Author" content="나만의닥터" />
        <meta httpEquiv="Other Agent" content="나만의닥터" />
        <meta httpEquiv="Copyright" content="(주)메라키플레이스" />
        {/*OG 친구들*/}
        <meta property="og:site_name" content="나만의닥터" />
        <meta property="og:locale" content="ko_KR" />
        {/* twitter, fb 등 미리보기 관련 */}
        <meta property="fb:pages" content="나만의닥터" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@나만의닥터" />
      </Head>
    </>
  );
};

export default ContentMeta;
