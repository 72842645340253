import { ColorKey, Font, SvgIconName } from "@/design-system";
import styled from "styled-components";
import { HospitalList } from "../..";
import { H2 } from "../../../design-system/Heading";
import Accordion from "./Accordion";

type Props = {
  sundayHospitalList: HospitalList[];
  nightHospitalList: HospitalList[];
  specialistHospitalList: HospitalList[];
  telemedicineHospitalList: HospitalList[];
};

const DepartmentHubSection = ({
  nightHospitalList,
  specialistHospitalList,
  sundayHospitalList,
  telemedicineHospitalList,
}: Props) => {
  const DEPARTMENT_HUB_LIST = [
    {
      title: "일요일 진료 병원",
      iconName: "calendarFilled",
      data: sundayHospitalList,
      color: "red700",
    },
    {
      title: "야간 진료 병원",
      iconName: "nightFilled",
      data: nightHospitalList,
      color: "yellow700",
    },
    {
      title: "전문의 진료 병원",
      iconName: "checkCircleFilled",
      data: specialistHospitalList,
      color: "green700",
    },
    {
      title: "비대면 진료 병원",
      iconName: "homePhoneFilled",
      data: telemedicineHospitalList,
      color: "accent",
    },
  ] as {
    title: string;
    data: HospitalList[];
    iconName: SvgIconName;
    color: ColorKey;
  }[];

  return (
    <Container>
      <TextContainer>
        <Font type="semibold_16" color="accent">
          일요일/야간 병원 찾기
        </Font>
        <H2
          fontType="h1"
          textAlign="center"
        >{`일요일, 야간에도\n문 연 병원 찾기`}</H2>
        <Font
          type="regular_16"
          color="medium"
          style={{ textAlign: "center" }}
        >{`365일 24시간 언제나  병원이 필요한 순간은\n나만의닥터와 함께 할게요.`}</Font>
      </TextContainer>
      <div style={{ width: "100%" }}>
        {DEPARTMENT_HUB_LIST.map((item) => (
          <Accordion
            key={item.title}
            color={item.color}
            title={item.title}
            data={item.data}
            iconName={item.iconName}
          />
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: white;
`;

const TextContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export default DepartmentHubSection;
