import { Color, Font, SvgIcon, SvgIconName } from "@/design-system";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";
import { H2, H3 } from "../../../design-system/Heading";

const HEALTH_CONTENT_LIST: {
  title: string;
  description: string;
  iconName: SvgIconName;
  backgroundColor: string;
  link: string;
}[] = [
  {
    title: "건강꿀팁",
    description: "쉽고 재밌게 만나는 건강 상식",
    iconName: "honey",
    backgroundColor: "#FFF6D1",
    link: "https://my-doctor.io/healthLab/info/list",
  },
  {
    title: "건강FAQ",
    description: "궁금했던 건강과 질환 정보 찾기",
    iconName: "faq",
    backgroundColor: "#EBF3FF",
    link: "https://my-doctor.io/healthLab/FAQ",
  },
  {
    title: "질환백과",
    description: "쉽게 재밌게 만나는 건강 상식",
    iconName: "book",
    backgroundColor: "#FFEBEB",
    link: "https://my-doctor.io/healthLab/health-wiki",
  },
];

const HealthContentSection = () => {
  return (
    <Container>
      <TextContainer>
        <Font type="semibold_16" color="accent">
          건강연구소
        </Font>
        <H2
          fontType="h1"
          textAlign="center"
        >{`나에게 딱 맞는\n건강 콘텐츠`}</H2>
        <Font
          type="regular_16"
          color="medium"
          style={{ textAlign: "center" }}
        >{`건강상식부터 세부적인 가격 정보까지\n쉽고 재밌는 건강 콘텐츠를 만나 보세요.`}</Font>
      </TextContainer>
      <ContentContainer>
        {HEALTH_CONTENT_LIST.map((content, index) => {
          return (
            <HealthContentItem
              key={content.title}
              onClick={() => window.open(content.link, "_blank")}
            >
              <ImageContainer backgroundColor={content.backgroundColor}>
                <SvgIcon iconName={content.iconName} size={36} />
              </ImageContainer>
              <ContentTextContainer>
                <H3 textAlign="left">{content.title}</H3>
                <Font type="regular_14" color="medium">
                  {content.description}
                </Font>
              </ContentTextContainer>
            </HealthContentItem>
          );
        })}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 60px 0;
  background-color: ${Color.greyScale2};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0 ${DEFAULT_PADDING}px;
  width: 100%;
  box-sizing: border-box;
`;

const HealthContentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 16px;
  width: 100%;
  background-color: ${Color.white};
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 0px rgba(30, 32, 36, 0.05);
  cursor: pointer;
`;

const ImageContainer = styled.div<{ backgroundColor: string }>`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ContentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

export default HealthContentSection;
