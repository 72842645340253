import { Button, Color, Font } from "@/design-system";
import { BottomSheet } from "@/global-components/components";
import useGetPlatform from "@/hooks/useGetPlatform";
import useMixpanel from "@/hooks/useMixpanel";
import Image from "next/image";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";
import { H2, H3 } from "../../../design-system/Heading";
import DesktopDownloadBottomSheet from "../DesktopDownloadBottomSheet";
import NudgeCarousel from "../NudgeCarousel";

const HomeContentSection = () => {
  const { platform } = useGetPlatform();
  const { track } = useMixpanel();

  const handlePressOneLink = () => {
    if (platform === "DESKTOP") {
      return BottomSheet.show({
        children: (
          <DesktopDownloadBottomSheet quickLink="https://my-doctor.app.link/sNIjxvg7uHb" />
        ),
      });
    }

    return window.open("https://my-doctor.app.link/sNIjxvg7uHb", "_blank");
  };

  return (
    <Wrapper>
      <Container backgroundColor={Color.white}>
        <TextContainer>
          <Font type="semibold_16" color="accent">
            비대면 진료
          </Font>
          <H2
            fontType="h1"
            textAlign="center"
          >{`전국민 누구나\n365일 24시간\n전화 진료가 가능해요`}</H2>
          <Font
            type="regular_16"
            color="medium"
            style={{ textAlign: "center" }}
          >{`비대면 진료 대상자라면 전화 진료 후\n약 배송까지 받을 수 있어요.`}</Font>
        </TextContainer>
        <ButtonContainer>
          <Button.Medium
            text={"비대면 진료 시작하기"}
            level="primary_neutral"
            onPress={() => {
              track("[Click] Download CTA on Home", {
                "Button Type": "비대면 진료 시작하기",
              });
              handlePressOneLink();
            }}
            type="hug"
          />
        </ButtonContainer>
        <Image
          src={"/home/home-first.png"}
          width={270}
          height={360}
          alt="firstContent"
          priority={true}
        />
      </Container>
      <Container backgroundColor={Color.greyScale2}>
        <TextContainer>
          <Font type="semibold_16" color="accent">
            닥터포인트
          </Font>
          <H2 textAlign="center">{`추가로 쌓이는 포인트로\n병원비 1/2로 줄이기`}</H2>
          <Font
            type="regular_16"
            color="medium"
            style={{ textAlign: "center" }}
          >{`비대면 진료와 대면 진료 시 비급여 진료비의\n최대 50%까지 사용 가능해요`}</Font>
        </TextContainer>
        <ButtonContainer>
          <Button.Medium
            text={"바로 건강지원금 3천원 받기"}
            level="primary_neutral"
            onPress={() => {
              track("[Click] Download CTA on Home", {
                "Button Type": "바로 건강지원금 3천원 받기",
              });
              handlePressOneLink();
            }}
            type="hug"
          />
        </ButtonContainer>
        <Image
          src={"/home/home-second.png"}
          width={270}
          height={360}
          alt="firstContent"
          priority={true}
        />
      </Container>
      <Container backgroundColor={Color.white}>
        <TextContainer>
          <Font type="semibold_16" color="accent">
            닥터캐시
          </Font>
          <H2
            fontType="h1"
            textAlign="center"
          >{`건강관리만 해도\n리워드가 쌓이는 앱테크`}</H2>
          <Font
            type="regular_16"
            color="medium"
            style={{ textAlign: "center" }}
          >{`만보기, 건강퀴즈, 체중기록, 혈압/혈당기록으로\n건강도 챙기고 돈도 벌어 보세요.`}</Font>
        </TextContainer>
        <ButtonContainer>
          <Button.Medium
            text={"건강관리 시작하기"}
            level="primary_neutral"
            onPress={() => {
              track("[Click] Download CTA on Home", {
                "Button Type": "건강관리 시작하기",
              });
              handlePressOneLink();
            }}
            type="hug"
          />
        </ButtonContainer>
        <NudgeTextContainer>
          <H3 textAlign="center">{`건강관리로 모은 닥터캐시는\n현금처럼 교환할 수 있어요`}</H3>
        </NudgeTextContainer>
        <div style={{ width: "100%" }}>
          <NudgeCarousel />
        </div>
        <Image
          src={"/home/secondContent.png"}
          width={270}
          height={418}
          alt="secondContent"
          priority={true}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div<{ backgroundColor: string }>`
  padding-top: ${DEFAULT_PADDING * 2}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${DEFAULT_PADDING}px 0;
  margin-bottom: 12px;
`;

const NudgeTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${DEFAULT_PADDING}px 0;
`;

export default HomeContentSection;
