import { Button, Color, Font } from "@/design-system";
import { BottomSheet } from "@/global-components/components";
import useGetPlatform from "@/hooks/useGetPlatform";
import useMixpanel from "@/hooks/useMixpanel";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";
import DesktopDownloadBottomSheet from "../DesktopDownloadBottomSheet";

const HomeSubsidySection = () => {
  const { platform } = useGetPlatform();
  const { track } = useMixpanel();

  const handlePressButton = () => {
    track("[Click] Download CTA on Home", {
      "Button Type": "Ending Section",
    });

    if (platform === "DESKTOP") {
      return BottomSheet.show({
        children: (
          <DesktopDownloadBottomSheet quickLink="https://my-doctor.app.link/sNIjxvg7uHb" />
        ),
      });
    }

    return window.open("https://my-doctor.app.link/sNIjxvg7uHb", "_blank");
  };

  const getIconName = () => {
    if (platform === "ANDROID") return "googlePlay";
    if (platform === "IOS") return "apple";
    return "qr";
  };

  return (
    <Container>
      <TextContainer>
        <Font type="semibold_24" color="white" style={{ textAlign: "center" }}>
          {`나만의닥터 앱에서\n병원비로 바로 사용 가능한\n건강지원금 3,000원을 드려요`}
        </Font>
      </TextContainer>
      <Button.Medium
        level="secondary"
        text={"앱 다운로드"}
        onPress={handlePressButton}
        type="hug"
        iconProps={{
          iconName: getIconName(),
          position: "leading",
          color: "accent",
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${DEFAULT_PADDING * 2}px;
  background-color: ${Color.accent};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${DEFAULT_PADDING}px;
  padding-bottom: 32px;
`;

export default HomeSubsidySection;
