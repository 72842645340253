import colors from "@/styles/colors";
import styled from "styled-components";

const Footer = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? "" : "100%")};
  max-width: 1200px;
  padding: ${(props) => (props.isMobile ? "0px 20px" : "0px")};
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white100};
  margin-top: 100px;
`;

const SalesAnnounce = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white100};
`;

const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.white100};
`;

const CopyrightText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.white100};
`;

const TermsArea = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  height: ${(props) => (props.isMobile ? "28px" : "42px")};
  align-items: center;
`;

const TextButton = styled.div<{ isMobile: boolean }>`
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? "13px" : "14px")};
  color: ${colors.white100};
  height: ${(props) => (props.isMobile ? "28px" : "42px")};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const VerticalBar = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.white100};
  font-size: 12px;
  margin: 0 8px;
  height: ${(props) => (props.isMobile ? "28px" : "42px")};
  padding-bottom: 2px;
`;

const IconArea = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: ${(props) => (props.isMobile ? "20px" : "0")};
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 16px;
  cursor: pointer;
`;

export {
  Footer,
  Title,
  ContentText,
  CopyrightText,
  Icon,
  IconArea,
  TermsArea,
  TextButton,
  VerticalBar,
  SalesAnnounce,
};
