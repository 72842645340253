export const CONTACT_POINT = [
  {
    "@type": "ContactPoint",
    telephone: "+82-1544-6172",
    email: "help@merakiplace.kr",
    contactType: "customer service",
  },
];

export const SITE_NAME = "나만의닥터";

export const SERVER_URL = "https://my-doctor.io";
// 나만의닥터 로고
export const LOGO_URL =
  "https://d7qkbi83dtokl.cloudfront.net/prod/uploads/ckeditor/2023/02/27/myd_icon.png";

export const SAME_AS = [
  "https://play.google.com/store/apps/details?id=com.merakiplace.mydoctor&pli=1",
  "https://apps.apple.com/us/app/%EB%82%98%EB%A7%8C%EC%9D%98%EB%8B%A5%ED%84%B0/id1584940053",
  "https://www.instagram.com/my_doctor_official/",
  "https://blog.naver.com/merakiplace_official",
  "https://post.naver.com/my.naver?memberNo=57509234",
  "https://www.youtube.com/channel/UC2IlIcvp7ffOfl1ue-vMfGw",
];
